/* eslint-disable @typescript-eslint/camelcase */
import {
  AI_INSIGHT_END,
  AI_INSIGHT_RESPONSE,
  AI_INSIGHT_SETUP,
  AI_STREAMING_END,
  AI_STREAMING_MSG,
  AI_STREAMING_RESPONSE,
  AI_STREAMING_SETUP,
  AI_VIRTUAL_AGENTS_INSIGHT_END,
  AI_VIRTUAL_AGENTS_INSIGHT_RESPONSE,
  AI_VIRTUAL_AGENTS_INSIGHT_SETUP,
  AI_VIRTUAL_AGENTS_STREAMING_END,
  AI_VIRTUAL_AGENTS_STREAMING_RESPONSE,
  AI_VIRTUAL_AGENTS_STREAMING_SETUP,
  BIDIRECTIONAL_STREAMING,
  SERVER_STREAMING,
  UNARY,
} from '../../constants/grpcConstants'

// TODO: create example dynamically using fns in grpcDocumentation
export const configObjects: { [key: string]: any } = {
  speech_insight: {
    InferStreamingSpeechInsights: {
      type: BIDIRECTIONAL_STREAMING,
      streamMessages: [
        {
          title: 'streaming_config',
        },
        {
          title: 'streaming_insight_config',
        },
      ],
      setupMessageName: AI_STREAMING_SETUP,
      streamMessageName: AI_STREAMING_MSG,
      responseMessageName: AI_STREAMING_RESPONSE,
      endMessageName: AI_STREAMING_END,
    },
    StreamingInsightServing: {
      type: SERVER_STREAMING,
      methodArgument: {
        title: 'insightServingRequest',
      },
      setupMessageName: AI_INSIGHT_SETUP,
      responseMessageName: AI_INSIGHT_RESPONSE,
      endMessageName: AI_INSIGHT_END,
    },
  },
  virtual_agents_transcript_and_call_summary: {
    StreamingInsightServing: {
      type: SERVER_STREAMING,
      methodArgument: {
        title: 'streamingInsightServingRequest',
      },
      setupMessageName: AI_VIRTUAL_AGENTS_STREAMING_SETUP,
      responseMessageName: AI_VIRTUAL_AGENTS_STREAMING_RESPONSE,
      endMessageName: AI_VIRTUAL_AGENTS_STREAMING_END,
    },
    InsightServing: {
      type: UNARY,
      methodArgument: {
        title: 'insightsServingRequest',
      },
      setupMessageName: AI_VIRTUAL_AGENTS_INSIGHT_SETUP,
      responseMessageName: AI_VIRTUAL_AGENTS_INSIGHT_RESPONSE,
      endMessageName: AI_VIRTUAL_AGENTS_INSIGHT_END,
    },
  },
}
