import React from 'react'

interface MessageContainerProps {
  messageTitle: string
  message: any
  updateMessage: any
  setJsonIsValid: any
}

const MessageContainer: React.FC<MessageContainerProps> = ({
  messageTitle,
  message,
  updateMessage,
  setJsonIsValid,
}) => {
  return (
    <>
      <p className="inputs-header">{messageTitle}</p>
      <div className="md-input-group message">
        <textarea
          className="md-input"
          onChange={(el): void => {
            try {
              JSON.parse(el.target.value)
              setJsonIsValid(true)
            } catch (e) {
              // Must be valid JSON in order to execute request
              setJsonIsValid(false)
            }

            updateMessage(el.target.value)
          }}
          value={message}
        ></textarea>
      </div>
    </>
  )
}

export default MessageContainer
