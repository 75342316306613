import { Button, Icon } from '@momentum-ui/react'
import React, { useEffect } from 'react'
import { useExpandTryOut } from '../../hooks/useExpandTryOut'

const ExpandTryOutBtn: React.FC = () => {
  const [isTryOutExpanded, toggleTryout] = useExpandTryOut()

  useEffect(() => {
    if (isTryOutExpanded) {
      toggleTryout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Button onClick={toggleTryout} className="expand-try-out-btn" size="none">
      {!isTryOutExpanded ? (
        <Icon name="icon-fullscreen_16" />
      ) : (
        <Icon name="icon-fullscreen-exit_16" />
      )}
    </Button>
  )
}

export default ExpandTryOutBtn
