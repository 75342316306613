import React from 'react'
import { IGrpcMethod, IGrpcService } from '../../interfaces/grpcInterfaces'
import GrpcMethodItem from '../GrpcMethodItem'

interface GrpcServiceItemProps {
  service: IGrpcService
}

const GrpcServiceItem: React.FC<GrpcServiceItemProps> = ({ service }) => {
  return (
    <section className="grpc-service-item-container">
      <h3 className="service-name" id={service.fullName}>
        {service.name}
      </h3>
      <p className="service-description">{service.description}</p>
      <ul className="method-items">
        {service.methods.map((method: IGrpcMethod, index: number) => {
          return <GrpcMethodItem key={index} method={method}></GrpcMethodItem>
        })}
      </ul>
    </section>
  )
}

export default GrpcServiceItem
