import { Badge } from '@momentum-ui/react'
import React from 'react'

interface RpcBadgeInterface {
  rpc: string
}

const RpcBadge: React.FC<RpcBadgeInterface> = ({ rpc }): JSX.Element | null => {
  const rpcBadge = (): JSX.Element | null => {
    const rpcs: { [key: string]: string } = {
      SERVER: 'rpc-server',
      CLIENT: 'rpc-client',
      BIDIRECTIONAL: 'rpc-bidirectional',
      UNARY: 'rpc-unary',
    }
    if (rpcs[rpc]) {
      return <Badge className={`rpc-badge ${rpcs[rpc]}`}>{rpc}</Badge>
    } else {
      return null
    }
  }

  return rpcBadge()
}

export default RpcBadge
