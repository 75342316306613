import { Breadcrumbs, Button, Select, SelectOption } from '@momentum-ui/react'
import { navigate } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeKebabCase, cleanApiName } from '../../utils/general.utils'
import ApiRoute from '../ApiRoute'
import Link from '../Link'

interface IApiHeadingProps {
  endpoint?: string
  badgeType?: string
  isDeprecated?: boolean
  name: string
  resourceName: string
  versions: number[]
  currentVersion: number
}

const ApiHeading: React.FC<IApiHeadingProps> = ({
  endpoint,
  badgeType,
  isDeprecated,
  name,
  resourceName,
  versions,
  currentVersion,
}) => {
  const { t } = useTranslation()
  const apiName = cleanApiName(name)
  const VERSION_LABEL = t('generalDocumentation.version')

  const renderApiRoute = (): React.ReactNode => {
    return badgeType && endpoint ? (
      <div className="url-container">
        <ApiRoute
          badgeType={badgeType}
          endpoint={endpoint}
          className="url"
          isDeprecated={isDeprecated}
        />
      </div>
    ) : null
  }
  const changeApiVersion = (version: string): void => {
    navigate(
      `/documentation/${makeKebabCase(
        resourceName
      )}/v${version}/${makeKebabCase(apiName)}`
    )
  }

  return (
    <div className="api-heading">
      <div className="name-version-container">
        <Breadcrumbs>
          <li>
            <Link
              to={`/documentation/${makeKebabCase(resourceName)}`}
              className="link"
            >
              {resourceName}
            </Link>
          </li>
          <li>{apiName}</li>
        </Breadcrumbs>
        {versions.length > 1 ? (
          <Select
            defaultValue={`${VERSION_LABEL} ${currentVersion}`}
            onSelect={(val: { [key: string]: string }[]): void => {
              changeApiVersion(val[0].value)
            }}
            className="api-version-select"
          >
            {versions?.map((version, i) => {
              return (
                <SelectOption
                  value={`${version}`}
                  label={`${VERSION_LABEL} ${version}`}
                  key={i}
                />
              )
            })}
          </Select>
        ) : (
          <Button size={36} disabled className="md-select disabled-select">
            {VERSION_LABEL} {currentVersion}
          </Button>
        )}
      </div>
      <h1>{apiName}</h1>
      {renderApiRoute()}
    </div>
  )
}

export default ApiHeading
