import { Badge } from '@momentum-ui/react'
import React from 'react'

interface IExampleProps {
  text: boolean | number | string
  type: string
}

export const exampleType: { [key: string]: string } = {
  BOOLEAN: 'ex-boolean',
  BOOL: 'ex-boolean',
  INTEGER: 'ex-number',
  'INTEGER[]': 'ex-number',
  INT32: 'ex-number',
  INT64: 'ex-number',
  UINT32: 'ex-number',
  FLOAT: 'ex-number',
  ENUM: 'ex-object',
  NUMBER: 'ex-number',
  'NUMBER[]': 'ex-number',
  STRING: 'ex-string',
  'STRING[]': 'ex-string',
  OBJECT: 'ex-object',
  'OBJECT[]': 'ex-object',
  BYTES: 'ex-byte',
}

const TypeBadge: React.FC<IExampleProps> = ({ text, type }): JSX.Element => {
  const exampleBadge = (
    propertyExample: boolean | number | string,
    propertyExampleType: string
  ): JSX.Element => {
    return (
      <Badge
        className={`type-badge ${
          exampleType[propertyExampleType] || 'ex-missing-type'
        }`}
      >
        {`${propertyExample}`}
      </Badge>
    )
  }

  return exampleBadge(text, type?.toUpperCase())
}

export default TypeBadge
