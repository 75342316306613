import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../state/store'
import { getExpandTryOutState, toggleTryout } from '../state/tryOut'

export const useExpandTryOut = (): [boolean, any] => {
  const isTryOutExpanded = useSelector((state: AppState) =>
    getExpandTryOutState(state)
  )
  const dispatch = useDispatch()
  const handleExpandTryOutClick = (): void => {
    dispatch(toggleTryout())
  }

  return [isTryOutExpanded, handleExpandTryOutClick]
}
