import { Tab, TabContent, TabList, TabPane, Tabs } from '@momentum-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ExpandTryOutBtn from '../ExpandTryOutBtn'

interface ITryOutAndSampleContainerProps {
  tabHeadings: string[]
  tabPanes: React.ReactElement[]
}

const TryOutAndSampleContainer: React.FC<ITryOutAndSampleContainerProps> = ({
  tabHeadings,
  tabPanes,
}) => {
  const { t } = useTranslation()

  return tabHeadings.length === 0 ||
    tabPanes.length === 0 ||
    tabPanes.length !== tabHeadings.length ? (
    <div className="try-out-error">{t('tryOut.errorMsg')}</div>
  ) : (
    <Tabs tabType="pills" className="try-out-and-sample-container">
      <TabList role="tab">
        {tabHeadings.map((heading, i) => (
          <Tab heading={heading} preventFocus key={i} />
        ))}
        <ExpandTryOutBtn />
      </TabList>

      <TabContent>
        {tabPanes.map((pane, i) => (
          <TabPane key={i}>{pane}</TabPane>
        ))}
      </TabContent>
    </Tabs>
  )
}

export default TryOutAndSampleContainer
