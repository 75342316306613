import { Language } from 'prism-react-renderer'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInterval } from '../../hooks/useInterval'
import { listenForMessage } from '../../services/socketService'
import CodeSnippet from '../CodeSnippet'

interface GrpcResponseBoxProps {
  messageName: string
  responseOverride?: string
  placeholderMessage: string
}

const GrpcResponseBox: React.FC<GrpcResponseBoxProps> = ({
  messageName,
  responseOverride,
  placeholderMessage,
}) => {
  const [messages, setMessages] = useState<string[]>([])
  const [userHasScrolled, setUserHasScrolled] = useState(false)
  const { t } = useTranslation()
  const buffer = useRef<string[]>([])

  const responseBox = document
    .getElementsByClassName(`grpc-response-${messageName}`)
    .item(0)
    ?.getElementsByTagName('pre')
    .item(0)

  responseBox?.addEventListener('scroll', (e) => {
    setUserHasScrolled(true)
  })

  useEffect(() => {
    listenForMessage(messageName, (msg) => {
      if (buffer.current.length === 15) {
        buffer.current.shift()
      }
      buffer.current.push(JSON.stringify(msg, null, 2))
    })
  }, [messageName])

  useInterval(() => {
    setMessages([...buffer.current])

    if (!userHasScrolled) {
      responseBox!.scrollTop =
        responseBox!.scrollHeight - responseBox!.clientHeight
    }
  }, 1000)

  const getCodeType = (): Language =>
    messages.length || responseOverride ? 'json' : 'javascript'

  const getCodeString = (): string => {
    if (responseOverride) {
      return responseOverride
    }

    if (messages.length === 0) {
      return `// ${placeholderMessage}`
    }

    let startingString = ''
    if (messages.length === 15) {
      startingString = `${t('grpcDocumentation.latestMessages')}`
    }

    return messages.reduce((accumulator, val) => {
      if (accumulator === '') {
        return val
      } else {
        return `${accumulator} \n${val}`
      }
    }, startingString)
  }

  return (
    <CodeSnippet
      canCopy={false}
      codeString={getCodeString()}
      className={`grpc-response-${messageName} ${
        messages.length ? 'has-response' : 'no-response'
      }`}
      language={getCodeType()}
      headerContentsLeftSide={<span>{t('generalDocumentation.response')}</span>}
    />
  )
}

export default GrpcResponseBox
