import { Button, Alert, AlertContainer } from '@momentum-ui/react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { API_TEST_RUN } from '../../constants/metrics'
import MetricsService from '../../services/metricsService'
import {
  emitMessage,
  listenForMessage,
  registerOpenStream,
} from '../../services/socketService'
import CodeSnippetHeader from '../CodeSnippet/CodeSnippetHeader'
import BearerTokenInput from '../BearerToken'
import GrpcResponseBox from '../GrpcResponseBox'
import {
  AI_STREAMING_MSG,
  SERVER_STREAMING,
} from '../../constants/grpcConstants'
import MessageContainer from './MessageContainer'
import { initRecorder, stopRecorder } from './audioProcessor'
import { logError } from '../../services/loggerService'
import { generateTrackingId } from '../../utils/general.utils'

interface GrpcTryOutProps {
  type: string
  grpcName: string
  methodArgument?: any
  streamMessages?: any[]
  setupMessageName: string
  streamMessageName?: string
  responseMessageName: string
  endMessageName: string
}

const GrpcTryOut: React.FC<GrpcTryOutProps> = ({
  type,
  grpcName,
  streamMessages,
  setupMessageName,
  streamMessageName,
  responseMessageName,
  methodArgument,
  endMessageName,
}) => {
  const { t } = useTranslation()
  const metricsService = MetricsService.getInstance()

  const [tokenFromInput, setTokenFromInput] = useState('')
  const [sendingRequest, setSendingRequest] = useState(false)
  const [jsonIsValid, setJsonIsValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [streamMessagesState, setStreamMessagesState] = useState(streamMessages)
  const [methodArgumentState, setMethodArgumentState] = useState(methodArgument)
  const [requestInitialized, setRequestInitialized] = useState(false)
  const [browserAudioAlert, setBrowserAudioAlert] = useState('')

  useEffect(() => {
    listenForMessage(endMessageName, () => {
      setSendingRequest(false)

      if (streamMessageName && streamMessageName === AI_STREAMING_MSG) {
        stopRecorder()
      }
    })
  }, [endMessageName, streamMessageName])

  const handleAuthTokenInput = (token: string): void => {
    if (token !== tokenFromInput) {
      setTokenFromInput(token)
    }
  }

  const parseMessage = (msg: any): any => {
    return typeof msg === 'string' ? JSON.parse(msg) : msg
  }

  const sendStreamMessages = async (): Promise<void> => {
    streamMessagesState!.forEach((msg) => {
      emitMessage(streamMessageName, parseMessage(msg.data))
    })
    if (streamMessageName === AI_STREAMING_MSG) {
      try {
        await initRecorder()
      } catch (e) {
        setSendingRequest(false)
        stopRecorder()

        if (e.message.includes('Permission denied')) {
          setBrowserAudioAlert('grpcDocumentation.micAccessDenied')
        } else {
          setBrowserAudioAlert('grpcDocumentation.audioAlert')
        }

        logError(`Error streaming audio: ${e.toString()}`)
      }
    }
  }

  const listenForDroppedConnection = (): void => {
    registerOpenStream(responseMessageName)

    listenForMessage(responseMessageName, (msg) => {
      if (msg.code === 14) {
        // connection dropped
        setSendingRequest(false)
      }
    })
  }

  const beginRequest = (): void => {
    if (!jsonIsValid) {
      setErrorMessage('{"error": "Request input must be valid JSON"}')
      metricsService.track(API_TEST_RUN, {
        apiName: grpcName,
        isSuccesful: false,
        errorsReturned: 'Invalid JSON input',
      })
      return
    } else if (jsonIsValid && errorMessage) {
      setErrorMessage('')
    }

    setSendingRequest(true)
    listenForDroppedConnection()
    metricsService.track(API_TEST_RUN, {
      apiName: grpcName,
      isSuccesful: true,
    })

    const setupMessage: any = {
      token: tokenFromInput,
      trackingId: generateTrackingId(),
    }
    if (methodArgumentState) {
      setupMessage.methodArgument = parseMessage(methodArgumentState.data)
    }
    emitMessage(setupMessageName, setupMessage, (callbackMessage: string) => {
      if (callbackMessage === 'OK') {
        setRequestInitialized(true)

        if (streamMessageName) {
          sendStreamMessages()
        }
      }
    })
  }

  const formatMessage = (msg: any): string => {
    return typeof msg === 'string' ? msg : JSON.stringify(msg, null, 2)
  }

  const toggleMicrophone = (): void => {
    if (sendingRequest) {
      endStream()
      stopRecorder()
    } else {
      beginRequest()
    }
  }

  const endStream = (): void => {
    emitMessage(endMessageName)
    setSendingRequest(false)
  }

  const getResponseBoxPlaceholder = (): string => {
    if (sendingRequest) {
      return t('tryOut.noResponseYet')
    } else if (streamMessageName === AI_STREAMING_MSG) {
      return `${t('tryOut.noResponseYet')} ${t('tryOut.startRecording')}`
    } else {
      return `${t('tryOut.noResponseYet')} ${t('tryOut.clickRunButton')}`
    }
  }

  return (
    <>
      <form className="try-out grpc-try-out">
        <CodeSnippetHeader
          canCopy={false}
          headerContentsLeftSide={
            <span>{t('generalDocumentation.request')}</span>
          }
        />
        <div className={`body ${requestInitialized && 'request-sent'}`}>
          <BearerTokenInput
            handleAuthTokenInput={(token: string): void =>
              handleAuthTokenInput(token)
            }
            toggleId={setupMessageName}
          />
          <hr />
          {methodArgumentState && (
            <MessageContainer
              messageTitle={methodArgument.title}
              message={formatMessage(methodArgumentState.data)}
              updateMessage={(msg: any): void =>
                setMethodArgumentState({ ...methodArgumentState, data: msg })
              }
              setJsonIsValid={(valid: boolean): void => setJsonIsValid(valid)}
            />
          )}
          {streamMessagesState &&
            streamMessagesState.map(
              (msg, index): JSX.Element => (
                <MessageContainer
                  key={index}
                  messageTitle={msg.title}
                  message={formatMessage(msg.data)}
                  updateMessage={(data: any): void => {
                    const updatedMessages = streamMessagesState.slice(0)
                    updatedMessages[index].data = data
                    setStreamMessagesState(updatedMessages)
                  }}
                  setJsonIsValid={(valid: boolean): void =>
                    setJsonIsValid(valid)
                  }
                />
              )
            )}
        </div>
        <div className="button-container">
          {streamMessageName === AI_STREAMING_MSG ? (
            <>
              <Button
                circle
                className="microphone"
                onClick={(): void => {
                  toggleMicrophone()
                }}
              >
                <span
                  className={`microphone-icon ${
                    sendingRequest ? 'recording' : ''
                  }`}
                ></span>
              </Button>
              <div className="mic-helper">
                {sendingRequest
                  ? t('grpcDocumentation.microphoneHelperStop')
                  : t('grpcDocumentation.microphoneHelper')}
              </div>
            </>
          ) : (
            <>
              <Button
                onClick={(): void => {
                  beginRequest()
                }}
                ariaLabel="Run Request"
                className="request-button"
                color="green"
                size="28"
                loading={sendingRequest}
              >
                {t('tryOut.run')}
              </Button>
              {type === SERVER_STREAMING && sendingRequest && (
                <Button
                  onClick={(): void => {
                    endStream()
                  }}
                  ariaLabel="End Server Stream"
                  className="request-button end-stream"
                  color="red"
                  size="28"
                >
                  {t('tryOut.endStream')}
                </Button>
              )}
            </>
          )}
        </div>
        <AlertContainer>
          <Alert
            className={'audio-alert-modal'}
            type={'error'}
            message={t(browserAudioAlert)}
            show={!!browserAudioAlert}
            dismissBtnProps={{
              onClick: (): void => setBrowserAudioAlert(''),
              ariaLabel: t('home.closeAlertAria'),
            }}
          />
        </AlertContainer>
      </form>
      <GrpcResponseBox
        messageName={responseMessageName}
        responseOverride={errorMessage}
        placeholderMessage={getResponseBoxPlaceholder()}
      />
    </>
  )
}

export default GrpcTryOut
