import { Input, InputHelper, ToggleSwitch } from '@momentum-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { retrieveToken } from '../../state/auth'
import { AppState } from '../../state/store'
import CopyButton from '../CopyButton'
import Tooltip from '../Tooltip'

interface IBearerTokenInputProps {
  handleAuthTokenInput: Function
  toggleId: string
}

const BearerTokenInput: React.FC<IBearerTokenInputProps> = ({
  handleAuthTokenInput,
  toggleId,
}) => {
  const userAuthToken = useSelector((state: AppState) => retrieveToken(state))
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [tokenEnteredByUser, setTokenEnteredByUser] = useState('')
  const [showPrefilledToken, setShowPrefilledToken] = useState(false)
  const handleToggleSwitch = (): void => {
    setShowPrefilledToken(!showPrefilledToken)
  }
  const { t } = useTranslation()

  useEffect(() => {
    if (userAuthToken) {
      setIsLoggedIn(true)
      setShowPrefilledToken(true)
      handleAuthTokenInput(userAuthToken)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (showPrefilledToken) {
      handleAuthTokenInput(userAuthToken)
    } else {
      handleAuthTokenInput(tokenEnteredByUser)
    }
  }, [
    handleAuthTokenInput,
    showPrefilledToken,
    userAuthToken,
    tokenEnteredByUser,
  ])

  return (
    <>
      <div className="input-outer-box single-label with-help-text no-margin">
        <span className="label">
          {t('bearerTokenInputComponent.authorization')}{' '}
          <span className="required">*</span>
        </span>
        <Tooltip
          tooltipText={t('bearerTokenInputComponent.authTokenMissingMessage')}
          className={'bearer-tooltip'}
          timer={500}
          disabled={isLoggedIn}
        >
          <ToggleSwitch
            checked={showPrefilledToken ? false : true}
            disabled={isLoggedIn ? false : true}
            label={
              isLoggedIn
                ? t('bearerTokenInputComponent.toggleHelpText')
                : t('bearerTokenInputComponent.toggleHelpTextNoAuth')
            }
            htmlId={`bearerTokenToggle-${toggleId}`}
            className="bearer-token-toggle"
            onChange={(): void => {
              handleToggleSwitch()
            }}
          />
        </Tooltip>
      </div>
      <div className="input-outer-box single-label with-help-text">
        <div className="bearer-token-input-container">
          {showPrefilledToken ? (
            <>
              <div className="input-and-copy">
                <Input
                  name="bearer-token"
                  htmlId="bearer-token"
                  type="password"
                  value={userAuthToken}
                  disabled
                />
                <CopyButton textToCopy={userAuthToken} />
              </div>
              <InputHelper
                message={t('bearerTokenInputComponent.authTokenHiddenText')}
                className="bearer-token-help-text"
              />
            </>
          ) : (
            <Input
              name="bearer-token-user-generated"
              htmlId="bearer-token-user-generated"
              placeholder={t(
                'bearerTokenInputComponent.bearerAuthTokenPlaceholder'
              )}
              onChange={(input: any): void => {
                setTokenEnteredByUser(input.target.value)
              }}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default BearerTokenInput
