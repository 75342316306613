import { useSelector } from 'react-redux'
import { IApi } from '../interfaces/contentInterface'
import { retrievePermittedData } from '../state/auth'
import { AppState } from '../state/store'
import { objIsEmpty, cleanApiName } from '../utils/general.utils'

export const useApiVersions = (apiName: string, apiType: string): number[] => {
  const permittedData = useSelector((state: AppState) =>
    retrievePermittedData(state)
  )
  if (objIsEmpty(permittedData)) {
    return []
  } else {
    return permittedData[apiType]
      .filter((api: IApi) => cleanApiName(api.name) === cleanApiName(apiName))
      .map((filteredApi: IApi) => filteredApi.version)
  }
}
