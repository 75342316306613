import React from 'react'
import RpcBadge from '../../components/RpcBadge'
import { IGrpcMethod } from '../../interfaces/grpcInterfaces'

export const getRpcType = (
  requestStreaming: boolean,
  responseStreaming: boolean
): string => {
  let rpcType = 'UNARY'
  if (requestStreaming) {
    if (responseStreaming) {
      rpcType = 'BIDIRECTIONAL'
    } else {
      rpcType = 'CLIENT'
    }
  } else {
    if (responseStreaming) {
      rpcType = 'SERVER'
    }
  }

  return rpcType
}

interface GrpcMethodItemProps {
  method: IGrpcMethod
}

const GrpcMethodItem: React.FC<GrpcMethodItemProps> = ({ method }) => {
  const getRpcSignature = (): JSX.Element => {
    return (
      <>
        rpc {method.name}(
        <span className="rpc-stream">
          {method.requestStreaming ? 'stream ' : ''}
        </span>
        <a href={`#${method.requestFullType}`}>{method.requestType}</a>) returns
        (
        <span className="rpc-stream">
          {method.responseStreaming ? 'stream ' : ''}
        </span>
        <a href={`#${method.responseFullType}`}>{method.responseType}</a>)
      </>
    )
  }
  return (
    <li className="grpc-method-item">
      <div className="rpc-badge-container">
        <RpcBadge
          rpc={getRpcType(method.requestStreaming, method.responseStreaming)}
        />
      </div>
      <h4 className="rpc-signature">{getRpcSignature()}</h4>
      <p className="method-description">{method.description}</p>
    </li>
  )
}

export default GrpcMethodItem
