import { AI_STREAMING_MSG } from '../../../constants/grpcConstants'
import { logError } from '../../../services/loggerService'
import { emitMessage } from '../../../services/socketService'

let audioStream: MediaStream
let audioContext: any

export const findAudioContext = (): boolean => {
  try {
    window.AudioContext = window.AudioContext || window.webkitAudioContext
    // Some browsers do not implement the newer version of mediaDevices or mediaDevices at all.
    // If possible, we will add the getUserMedia property if it does not exist by implementing legacy getUserMedia.
    navigator.mediaDevices.getUserMedia =
      navigator.mediaDevices.getUserMedia ||
      // Legacy mediaDevices
      navigator.mediaDevices ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia
  } catch (e) {
    return false
  }

  if (!navigator.mediaDevices.getUserMedia || !window.AudioContext) {
    return false
  }
  return true
}

const connectAudioContext = async (stream: MediaStream): Promise<void> => {
  try {
    audioContext = new AudioContext({
      sampleRate: 16000,
    })
    // Capture mic audio data into a stream
    const audioInput = audioContext.createMediaStreamSource(stream)
    // Create Audio Processor instance
    await audioContext.audioWorklet.addModule('/audio-processor.js')
    const AudioNode = new AudioWorkletNode(audioContext, 'audio-processor')

    // Connect stream to our processor
    audioInput.connect(AudioNode).connect(audioContext.destination)

    // Send processed audio
    AudioNode.port.onmessage = (data): void => {
      // eslint-disable-next-line @typescript-eslint/camelcase
      emitMessage(AI_STREAMING_MSG, { audio_content: data.data.buffer })
    }
  } catch (e) {
    logError(`Unable to connect to audio source: ${e.toString()}`)
  }
}

export const stopRecorder = (): void => {
  audioContext?.close()
  audioContext = null
  audioStream?.getAudioTracks().forEach((track) => {
    track.stop()
  })
}

export const initRecorder = async (): Promise<void> => {
  if (!findAudioContext()) {
    throw new Error('Your browser is not supported')
  }

  return navigator.mediaDevices
    .getUserMedia({ audio: true, video: false })
    .then((stream) => {
      audioStream = stream
      connectAudioContext(stream)
    })
}
