import { graphql } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import GrpcItem from '../../components/GrpcItem'
import GrpcServiceItem from '../../components/GrpcServiceItem'
import GrpcTryOut from '../../components/GrpcTryOut'
import TryOutAndSampleContainer from '../../components/TryOutAndSampleContainer'
import { useApiVersions } from '../../hooks/useApiVersions'
import {
  IGrpcDiscoveredItemSimpleInfo,
  IGrpcDiscoveries,
  IGrpcDocumentation,
  IGrpcEnum,
  IGrpcFile,
  IGrpcMessages,
  IGrpcService,
} from '../../interfaces/grpcInterfaces'
import ApiLayout from '../../layouts/ApiLayout'
import { configObjects } from './grpc-services-config'
import { makeSnakeCase } from '../../utils/general.utils'

export interface IGrpcDocumentationProps {
  data: {
    strapiGrpcs: {
      data: IGrpcDocumentation
    }
  }
  pageContext: {
    name: string
    resourceName: string
    version: number
  }
}

export const query = graphql`
  query GrpcApiQuery($name: String!, $version: Int!) {
    strapiGrpcs(name: { eq: $name }, version: { eq: $version }) {
      data
    }
  }
`

const GrpcDocumentation: React.FC<IGrpcDocumentationProps> = ({
  data,
  pageContext,
}): JSX.Element => {
  const grpc = data.strapiGrpcs
  const {
    name: apiName,
    version: apiCurrentVersion,
    resourceName,
  } = pageContext
  const { t } = useTranslation()
  const apiVersionNums = useApiVersions(apiName, 'grpcs')

  const getGrpcEnumItems = (): IGrpcDiscoveries => {
    const itemsDiscovered = [] as IGrpcDiscoveredItemSimpleInfo[]
    const jsxItems = grpc.data.files.map((protobufFile: IGrpcFile) => {
      return protobufFile.enums.map((enumeration: IGrpcEnum, i: number) => {
        itemsDiscovered.push({
          name: enumeration.name,
          longName: enumeration.longName,
          fullName: enumeration.fullName,
        })
        return (
          <GrpcItem
            key={i}
            id={enumeration.fullName}
            name={enumeration.longName}
            description={enumeration.description}
            fields={enumeration.values}
          />
        )
      })
    })

    return {
      jsxItems,
      itemsDiscovered: { itemType: 'enums', items: itemsDiscovered },
    }
  }

  const getGrpcServiceItems = (): IGrpcDiscoveries => {
    const itemsDiscovered = [] as IGrpcDiscoveredItemSimpleInfo[]
    const jsxItems = grpc.data.files.map((protobufFile: IGrpcFile) => {
      return protobufFile.services.map((service: IGrpcService, i) => {
        itemsDiscovered.push({
          name: service.name,
          longName: service.longName,
          fullName: service.fullName,
        })
        return <GrpcServiceItem key={i} service={service} />
      })
    })

    return {
      jsxItems,
      itemsDiscovered: { itemType: 'services', items: itemsDiscovered },
    }
  }

  const getGrpcMessageItems = (): IGrpcDiscoveries => {
    const itemsDiscovered = [] as IGrpcDiscoveredItemSimpleInfo[]
    const jsxItems = grpc.data.files.map(
      (protobufFile: IGrpcFile, idx: number) => {
        return protobufFile.messages.map(
          (message: IGrpcMessages, i: number) => {
            itemsDiscovered.push({
              name: message.name,
              longName: message.longName,
              fullName: message.fullName,
            })
            return (
              <GrpcItem
                isActive={idx < 1 && i < 2}
                key={i}
                id={message.fullName}
                name={message.name}
                description={message.description}
                fields={message.fields}
              />
            )
          }
        )
      }
    )

    return {
      jsxItems,
      itemsDiscovered: { itemType: 'messages', items: itemsDiscovered },
    }
  }

  const serviceDiscoveries = getGrpcServiceItems()

  const messageDiscoveries = getGrpcMessageItems()

  const enumDiscoveries = getGrpcEnumItems()

  const getMethods = (
    data: IGrpcDocumentation
  ): { [name: string]: string }[] => {
    const methods: { [name: string]: string }[] = []

    if (data && Array.isArray(data.files)) {
      data.files.forEach((file) => {
        if (file.hasServices && Array.isArray(file.services)) {
          file.services.forEach((service) => {
            if (Array.isArray(service.methods)) {
              service.methods.forEach((method) => {
                methods.push({
                  name: method.name,
                })
              })
            }
          })
        }
      })
    }

    return methods
  }

  // TODO: Create API configs dynamically for the entire config. Below, we are only creating request body examples.
  const getDefaultValue = (field: {
    [key: string]: any
  }): string | number | boolean => {
    switch (field.type) {
      case 'string':
        return ''
      case 'int32':
      case 'int64':
        return 0
      case 'float':
        return 0.0
      case 'bool':
        return false
      default:
        return ''
    }
  }

  const createExampleObject = (
    messages: { [key: string]: any },
    messageType: string
  ): { [key: string]: any } | '' => {
    const message = messages.find(
      (msg: { [key: string]: any }) => msg.name === messageType
    )
    if (!message) {
      return ''
    }

    const exampleObject: { [key: string]: any } = {}
    message.fields.forEach((field: { [key: string]: any }) => {
      if (field.fullType) {
        exampleObject[field.name] = createExampleObject(
          messages,
          field.longType
        )
      } else {
        exampleObject[field.name] = getDefaultValue(field)
      }
    })

    return exampleObject
  }

  const createExampleRequest = (
    data: IGrpcDocumentation
  ): { [key: string]: any } => {
    const exampleRequests: { [key: string]: any } = {}

    data.files.forEach((file) => {
      if (file.services && file.services.length > 0) {
        file.services.forEach((service) => {
          service.methods.forEach((method) => {
            const requestType = method.requestType

            const exampleRequest = createExampleObject(
              file.messages,
              requestType
            )

            if (exampleRequest) {
              exampleRequests[method.name] = exampleRequest
            }
          })
        })
      }
    })

    return exampleRequests
  }

  const exampleRequests = createExampleRequest(grpc.data)

  const createExampleRequestBody = (
    apiConfig: { [key: string]: any },
    grpcMethod: string
  ): void => {
    const apiByMethod = apiConfig[makeSnakeCase(apiName)][grpcMethod]

    if (apiByMethod.streamMessages) {
      apiByMethod.streamMessages.forEach((stream: { [key: string]: any }) => {
        stream.data = exampleRequests[grpcMethod][stream.title]
      })
    } else {
      apiConfig[makeSnakeCase(apiName)][grpcMethod].methodArgument.data =
        exampleRequests[grpcMethod]
    }
  }

  const getTabs = (): string[] | undefined => {
    return getMethods(grpc.data).map((method) => method.name)
  }

  const getTabPanes = (): JSX.Element[] | undefined => {
    return getTabs()?.map((name) => {
      createExampleRequestBody(configObjects, name)
      return (
        <GrpcTryOut
          {...configObjects[makeSnakeCase(apiName)][name]}
          grpcName={name}
          key={name}
        />
      )
    })
  }

  const leftSideContents = (
    <>
      <section className="documentation-section">
        <h2 className="documentation-section-header" id="services">
          {t('grpcDocumentation.services')}
        </h2>
        {serviceDiscoveries.jsxItems}
      </section>
      <section className="documentation-section">
        <h2 className="documentation-section-header" id="messages">
          {t('grpcDocumentation.messages')}
        </h2>
        {messageDiscoveries.jsxItems}
      </section>
      <section className="documentation-section">
        <h2 className="documentation-section-header" id="enums">
          {t('grpcDocumentation.enums')}
        </h2>
        {enumDiscoveries.jsxItems}
      </section>
    </>
  )

  const rightSideContents = (
    <TryOutAndSampleContainer
      tabHeadings={getTabs()!}
      tabPanes={getTabPanes()!}
    />
  )

  return (
    <ApiLayout
      apiName={apiName}
      resourceName={resourceName}
      description={grpc.data.description}
      leftSideContents={leftSideContents}
      rightSideContents={rightSideContents}
      versions={apiVersionNums}
      currentVersion={apiCurrentVersion}
    />
  )
}

export default GrpcDocumentation
