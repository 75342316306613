import { ListItemSection } from '@momentum-ui/react'
import React, { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { linkRenderer } from '../../utils/markdown.utils'
import TypeBadge from '../TypeBadge'
import MissingProperty from '../MissingProperty'
import {
  Accordion,
  AccordionContent,
  AccordionGroup,
  AccordionHeader,
} from '@momentum-ui/react'
import { useTranslation } from 'react-i18next'
interface IPropertyListItemProps {
  name: string
  description?: string
  type: string
  example?: string | boolean | number
  children?: React.ReactNode
  className?: string
  isLink?: boolean
  link?: string
  required?: boolean
  format?: string
}

const PropertyListItem: React.FC<IPropertyListItemProps> = ({
  name,
  description,
  type,
  example,
  children,
  className,
  isLink,
  link,
  required,
  format,
}) => {
  const { t } = useTranslation()
  const hasValidChildren = (
    child: React.ReactNode | React.ReactNode[]
  ): boolean => {
    const isNull = (value: React.ReactNode | React.ReactNode[]): boolean => {
      return value ? false : true
    }

    if (!child || (Array.isArray(child) && child.every(isNull))) {
      return false
    }
    return true
  }
  const hasClassNameProp = (): string => {
    return className
      ? `property-list-item ${className}`
      : 'property-list-item property-list-item-default-border'
  }

  const createType = (): JSX.Element => {
    const propertyType = format || type
    if (isLink) {
      return (
        <a href={`#${link}`} className="link">
          {propertyType}
        </a>
      )
    } else if (!propertyType) {
      return <MissingProperty name="type and/or format" className="type" />
    } else {
      return <TypeBadge text={propertyType} type={type} />
    }
  }
  const innerContent = (): JSX.Element => {
    if (!hasValidChildren(children)) {
      return (
        <>
          {description ? (
            <ReactMarkdown
              source={ensureEndsWithPeriod(description)}
              renderers={linkRenderer}
            />
          ) : (
            <MissingProperty name="description" />
          )}

          {example !== undefined ? (
            <p>
              {t('generalDocumentation.example')}{' '}
              <span className="example">{`${JSON.stringify(example)}`}</span>
            </p>
          ) : (
            <MissingProperty name="example" />
          )}
        </>
      )
    } else {
      return <ul>{children}</ul>
    }
  }

  const ensureEndsWithPeriod = (description: string): string => {
    return description.charAt(description.length - 1) === '.'
      ? description
      : `${description}.`
  }

  if (hasValidChildren(children)) {
    return (
      <Accordion
        multipleVisible
        initialActive={[0]}
        initialActiveFocus={false}
        className={`container-bottom ${hasClassNameProp()} hasChildren`}
      >
        <AccordionGroup>
          <AccordionHeader
            className={`container-header ${hasClassNameProp()} hasChildren`}
          >
            <div className="property-heading">
              <div className="name">
                {name}
                {required && <span className="required">*</span>}
              </div>
              <div>{createType()}</div>
            </div>
          </AccordionHeader>
          <AccordionContent
            className={`${hasClassNameProp()}
              hasChildren`}
          >
            {innerContent()}
          </AccordionContent>
        </AccordionGroup>
      </Accordion>
    )
  } else {
    return (
      <li className={`${hasClassNameProp()} `}>
        <ListItemSection>
          <div className="container">
            <div className="property-heading">
              <div className="name">
                {name}
                {required && <span className="required">*</span>}
              </div>
              <div>{createType()}</div>
            </div>
            {innerContent()}
          </div>
        </ListItemSection>
      </li>
    )
  }
}

export default PropertyListItem
