import React from 'react'
import {
  IGrpcEnumValue,
  IGrpcMessageField,
} from '../../interfaces/grpcInterfaces'
import PropertyListItem from '../PropertyListItem'
import {
  Accordion,
  AccordionContent,
  AccordionGroup,
  AccordionHeader,
} from '@momentum-ui/react'
interface GrpcItemProps {
  isActive?: boolean
  id: string
  name: string
  description: string
  fields: IGrpcEnumValue[] | IGrpcMessageField[]
}

const GrpcItem: React.FC<GrpcItemProps> = ({
  isActive,
  id,
  name,
  description,
  fields,
}) => {
  const getType = (field: IGrpcEnumValue | IGrpcMessageField): string => {
    if ((field as IGrpcEnumValue).number) {
      return 'enum'
    } else if (field.longType) {
      return field.longType
    } else {
      return field.type as string
    }
  }
  return (
    <section className="grpc-item-container">
      <Accordion
        multipleVisible
        initialActive={isActive ? [0] : []}
        initialActiveFocus={false}
      >
        <AccordionGroup>
          <AccordionHeader className="grpc-item-accordion-header">
            <div>
              <h3 className="item-name" id={id}>
                {name}
              </h3>
              <p className="item-description">{description}</p>
            </div>
          </AccordionHeader>
          <AccordionContent>
            <ul className="field-items">
              {(fields as Array<IGrpcEnumValue | IGrpcMessageField>).map(
                (field: IGrpcEnumValue | IGrpcMessageField, i: number) => {
                  return (
                    <PropertyListItem
                      key={i}
                      name={field.name}
                      description={field.description}
                      type={getType(field)}
                      isLink={field.fullType !== field.type}
                      link={field.fullType}
                      format={
                        (field as IGrpcEnumValue).number
                          ? (field as IGrpcEnumValue).number
                          : ''
                      }
                    />
                  )
                }
              )}
            </ul>
          </AccordionContent>
        </AccordionGroup>
      </Accordion>
    </section>
  )
}

export default GrpcItem
